







































import { Component } from 'vue-property-decorator';
import EditButton from '@/components/common/EditButton.vue';
import BaseCrudDialog from '@/components/base/BaseCrudDialog';
import CategoryInterface from '@/interfaces/CategoryInterface';
import CreateButton from '@/components/common/CreateButton.vue';
import RemoveButton from '@/components/common/RemoveButton.vue';

@Component({
    components: {
        EditButton,
        CreateButton,
        RemoveButton
    }
})
export default class CategoryDialog extends BaseCrudDialog {
    public entityType: string = 'category';
    public entity: CategoryInterface = {} as CategoryInterface;
}
