
















































import { DataTableHeader } from 'vuetify';
import BaseList from '@/components/base/BaseList';
import { Component } from 'vue-property-decorator';
import CategoryApi from '@/services/api/CategoryApi';
import Actions from '@/components/renderless/Actions';
import CategoryDialog from '@/components/categories/CategoryDialog.vue';

@Component({
    components: {
        Actions,
        CategoryDialog
    }
})
export default class CategoryList extends BaseList {
    public apiClient = (new CategoryApi());

    public headers: DataTableHeader[] = [
        { text: '№', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: '', value: 'actions', align: 'end' }
    ];
}
